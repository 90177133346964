export const en = {
  'value': 'ENG',
  'idCode': 'ID code',
  'firstName': 'First name',
  'lastName': 'Last name',
  'shortName': 'Short name',
  'email': 'Email address',
  'permissions': 'Permissions',
  'language': 'Language',
  'converter': {
    'converter': 'Converter',
    'headers': 'Headers',
    'code': 'Code',
    'horizontal': 'Horizontal',
    'vertical': 'Vertical',
    'checkbox': 'Checkbox',
    'buttons': 'Buttons',
    'removeAll': 'Remove all',
    'addAll': 'Add all',
    'convertFile': 'Convert file',
    'keep': 'Keep',
    'discard': 'Discard',
    'showFinal': 'Show final',
    'convert': 'Convert file',
    'reconvert': 'Convert the base file',
    'resultConvert': 'Convert the result file',
    'continueEditing': 'Continue editing',
    'inProgress': 'Please wait',
    'previousConversion': 'Previous conversion | Previous conversions',
    'previousUploads': 'Previous uploads',
    'giveName': 'Give a name',
    'projectCode': 'Project code',
    'columnSelect': 'Column select',
    'personCodeGroup': 'SKOODi group',
    'selectPersonCodeGroup': 'Select SKOODi group',
    'sampleCodeGroup': 'VKOODi group',
    'selectSampleCodeGroup': 'Select VKOODi group',
    'btn-reset': 'Reset',
    'btn-copyExcel': 'Copy excel',
    'btn-copyJson': 'Copy JSon',
    'step': {
      '1': 'Select Code',
      '2': 'Select Columns',
      '3': 'Preview',
    },
    'instruction': {
      '1': 'Find a column that contains a code and click on its corresponding letter',
      '2': 'Mark columns to be removed by clicking on headers',
      '3': 'Make sure you have left only necessary columns',
    }
  },
  'nav': {
    'releases': 'Releases',
    'home': 'Home',
    'uploads-list': 'Files',
    'conversions-list': 'Conversions',
    'users': 'Users',
    'logout': 'Logout',
    'projects-list': 'Projects',
    'code-usage': 'Code usage',
    'back': 'Back',
  },
  'login': {
    'sign-in': 'Please sign in',
    'message-sent': 'Message sent',
    'check-code': 'Check code: ',
    'cancel': 'Cancel',
    'smart-ID': 'Smart-ID',
    'smart-ID-button': 'Sign in with Smart-ID',
    'smart-ID-instructions': 'After making sure there is a correct check code enter Smart-ID PIN1 on your phone.',
    'ID-card': 'Sign in with ID-card',
    'mobile-ID': 'Mobile-ID',
    'mobile-ID-button': 'Sign in with Mobile-ID',
    'mobile-ID-instructions': 'After making sure there is a correct check code enter Mobiil-ID PIN1 on your phone.',
    'phoneNo': 'Phone number',
    'login': 'Login'
  },
  'file': {
    'state': 'State',
    'name': 'Name',
    'size': 'Size',
    'lines': 'Lines',
    'samples': 'Samples',
    'people': 'People',
    'missing': 'Missing',
    'previewMissing': 'Preview missing codes',
    'original': 'Input file',
    'complete': 'Work file',
    'release': 'Release file',
    'filter': {
      'title': 'Filter options',
      'id': {
        'field': 'File id:',
        'placeholder': 'File id',
      },
      'name': {
        'field': 'File name:',
        'placeholder': 'File name',
      },
      'uploaders': {
        'field': 'Uploaders:',
        'placeholder': 'Select uploaders',
      },
      'states': {
        'field': 'States:',
        'placeholder': 'Select states',
      },
      'projects': {
        'field': 'Project name:',
        'placeholder': 'Select project',
      },
    },
    'converted': 'Converted: ',
    'info': 'Info',
    'upload': 'Uploaded file',
    'uploadedFiles': 'Uploaded files',
    'uploadedAt': 'Uploaded',
    'uploadedBy': 'Uploader',
    'convertedAt': 'Converted',
    'convertedBy': 'Converter',
    'lastModifiedAt': 'Last modified',
    'lastModifiedBy': 'Last modifier',
    'preview': 'Preview',
    'download': 'Download file',
    'downloadName': 'Downloaded file custom name',
    'noConversions': 'No previous conversions',
    'noProject': 'No project affiliation',
  },
  'user': {
    'create': 'Create user',
    'update': 'Update user',
    'isActive': 'User status:',
    'active': 'Active',
    'disabled': 'Disabled',
    'list': 'User list',
  },
  'home': {
    'homePage': 'Releases home page',
    'siteDescription': 'This application enables user to convert .csv and .txt with the correct codes(vkood, ukood, skood, lkood)',
    'pageDescriptions': 'Pages and their usage',
    'conversionsDescription': 'The main functionality of the site is done on this page. Upload file and get it converted with needed codes and columns.',
    'usersDescription': 'Create users and mange their rights.',
    'eventHistory': 'Latest activity'
  },
  'apiKey': {
    'title': 'API key',
    'info': ' JS based add-in for Excel to simplify coding for releases procedure. Tested to work with O365 on macOS and Windows.',
    'exists': 'You have already have API key.',
    'genWarn': 'Generating new one will destroy the old Api key - reconfiguration needed.',
    'genInfo': 'You currently don\'t have API key. Generate one if you need it for your Excel addin.',
    'copy': 'Copy',
    'generate': 'Create',
    'destroy': 'Destroy',
    'true': 'Active',
    'false': 'No key',
  },
  'codeRelations': {
    'title': 'Update code relations',
    'info': 'Rebuild code relations tables from live data.',
    'update': 'Update',
    'updating': 'Updating',
    'updateSuccess': 'New code relations successfully created',
  },
  'upload': {
    'new': 'Upload new file',
    'upload': 'Upload',
    'browse': 'Browse',
    'choose': 'Choose file',
  },
  'conversions':{
    'result': 'Convert result',
    'filter': {
      'title': 'Conversions filtering',
      'projects': {
        'field': 'Project code:',
        'placeholder': 'Project code',
      },
      'converter': {
        'field': 'Converter:',
        'placeholder': 'Select user',
      },
    },
  },
  'project': {
    'project': 'Project',
    'projects': 'Projects',
    'name': 'Name',
    'code': 'Project code',
    'create': 'Create project',
    'edit': 'Edit project',
    'createdBy': 'Created by',
    'createdAt': 'Created at',
    'lastModifiedBy': 'Last modified by',
    'lastModifiedAt': 'Last modified at',
    'missing': 'Missing',
    'attach': 'Attach to project',
    'quickSelect': 'Quick select',
    'filter': {
      'title': 'Project filtering',
      'projects': {
        'field': 'Code:',
        'placeholder': 'Project code',
      },
      'name': {
        'field': 'Name:',
        'placeholder': 'Project name',
      },
      'creator': {
        'field': 'Creator:',
        'placeholder': 'Select user',
      },
    },
    'form-name': 'Project name',
    'form-code': 'Project code',
    'btn-create': 'Create project',
  },
  'code-usage': {
    'usage': 'Usage info',
    'lookup': 'Lookup code usages',
    'code': 'Code',
    'type': 'Type',
    'ukood': 'UKOOD',
    'lkood': 'LKOOD',
    'usageCount': 'Usages count',
    'listView': 'View conversion list',
    'rapport': 'Rapport',
    'show-ukood-list': 'Show ukood {0} conversions list',
    'show-lkood-list': 'Show lkood {0} conversions list',
    'download-ukood-rapport': 'Download ukood {0} rapport',
    'download-lkood-rapport': 'Download lkood {0} rapport',
    'unknown-code': 'Unknown code "{0}"',
  },
  'release': {
    'released': 'Released',
    'files':'Files',
    'people':'People',
    'samples':'Samples',
  },
  'filter': {
    'btn-filter': 'Filter',
    'btn-filter-active': 'Filter ({found}/{total})',
    'btn-close': 'Close',
    'btn-clear': 'Clear filter',
  },
  'multiselect': {
    'selectLabel': 'Press to select',
    'selectedLabel': 'Selected',
    'deselectLabel': 'Press to remove',
  },
  'events': {
    'history': 'History',
    'type': 'Action',
    'created-at': 'Date',
    'created-by': 'Actor',
  },
  'error': {
    1: 'Logging in error:',
    2: 'Needed to be logged in:',
    3: 'Required value missing:',
    4: 'Auth missing active session:',
    5: 'System problem:',
    6: 'Invalid value:',
    7: 'Invalid permission:',
    8: 'Connection issue:',
  },
  'fieldMessage': {
    'NO_GENS': 'Failed to generated codes based on column and code selection. Make sure right column and code is selected.',
    'REQUIRED': 'Required',
    'POS_INT': 'Must be a positive integer',
    'POS_INT_NUM': 'Must be a positive integer over {0}',
    'TYPE_STRING': 'Must be text',
    'TYPE_EMAIL': 'Non valid email',
    'TYPE_ARRAY': 'Must be array',
    'TYPE_NUM': 'Must be number - "{0}"',
    'TYPE_PROJECT_CODE': 'Must be (Capital letter)(number)(number)',
    'TEXT_LENGTH': 'Allowed length form {0} to {1} characters',
    'ID_CODE_LONG': 'ID code too long - must be 11 digits',
    'ID_CODE_SHORT': 'ID code too short - must be 11 digits',
    'ID_CODE_CHECKSUM': 'ID checksum mismatch - check order of digits',
    'UNKNOWN_TYPE': 'Non recognized type - "{0}"',
    'UNKNOWN_STATE': 'Non recognized state - "{0}"',
    'UNKNOWN_PERMISSION': 'Non recognized permission - "{0}"',
    'NOT_IN_USAGE': 'Not in usage',
    'IN_USAGE': 'Already in usage - must be unique',
    'DB_ERROR': 'Database error',
    'NO_PERMISSION': 'No permission',
    'sid': {
      'USER_REFUSED': 'Authentication canceled',
      'TIMEOUT': 'Authentication session expired. Try again.',
      'DOCUMENT_UNUSABLE': 'Please check your certificate validity from SmartID app',
      'WRONG_VC': 'Wrong verification code selected.',
      'sys_error': 'EstEID system error',
      'not_implemented': 'EstEID error - feature not implemented.',
    },
    'mid': {
      'TIMEOUT': 'Authentication session expired. Try again.',
      'NOT_MID_CLIENT': 'No active certificate',
      'USER_CANCELLED': 'Authentication canceled',
      'SIGNATURE_HASH_MISMATCH': 'Mobile-ID configuration on user\'s SIM card differs from what is configured on service provider\'s side.', // Mobile-ID configuration on user's SIM card differs from what is configured on service provider's side.
      'PHONE_ABSENT': 'Mobile-ID provider did not reach your phone.',
      'DELIVERY_ERROR':'Mobile-ID provider did not reach your phone.',
      'SIM_ERROR': 'Mobile-ID provider did not reach your phone.',
      'sys_error': 'EstEID system error',
      'not_implemented': 'EstEID error - feature not implemented.',
    },
  },
  'successMessage': {
    'userUpdated': 'User updated',
    'userCreated': 'User created',
    'PROJECT_CREATED': 'Project created',
    'ATTACHED_TO_PROJECT': 'Successfully attached to project',
  },
};
